import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, grayscale } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

export const Container = styled.div`
  background: ${grayscale['100']};
  border-radius: ${rounded['5']};
  width: 312px;
  min-height: 617px;
  padding: 16px 16px 34.5px 0;
  
  @media ${device.desktopLG} {  
    height: 622px; 
    position: sticky;
    top: 150px;
    width: auto;
  } 

  h3 {
    padding-left: 16px;
    margin-bottom: 42.5px;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      padding-bottom: 61px;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        border-left: 2px solid ${grayscale['200']};
        color: ${grayscale['500']};
        height: 24px;
        padding-left: 14px;
        float: left;
        align-items: center;
        display: flex;

        &.active{
          border-left: 2px solid ${orange.extra};
          font-weight: 700;
        }
      }
    }
  }
`
