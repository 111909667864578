
import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, grayscale, white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

type ButtonProps = {
  modalOpen: string;
}

export const ContainerDocument = styled.section`
  min-height: 100vh;   

  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }

  span {
    
    &.active {
      color: ${orange.extra};
      font-weight: 700;
    }
  }

  hr {
    border: 1px solid ${grayscale['200']};
    margin: 24px 0;

    @media ${device.tablet} {   
      margin-bottom: 83px;
    }  
  } 
`

export const DocumentPage = styled.div`
  h3 {
    color: ${orange.extra};
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 20px;

    @media ${device.tablet} {   
      font-size: 32px;
      line-height: 37px;
    } 
  }

  h4 {
    color: ${grayscale['500']};
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 20px;

    @media ${device.tablet} {   
      font-size: 24px;
      line-height: 28px;
    } 
  }

  p, li {
    color: ${grayscale['500']};
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    margin-bottom: 20px;
    word-break: break-word;

    @media ${device.tablet} {   
      font-size: 18px;
      line-height: 22px;
      word-break: normal;
    } 
  }

  span {
    font-size: 18px;
    line-height: 22px;
    margin: 20px 0;
    color: ${grayscale['500']};
    display: block;
  }

  a {
    margin-bottom: 20px;
    font-weight: 600;
    word-break: break-all;

    @media ${device.tablet} {
      word-break: normal;
    }
  }

  ul {
    li {
     margin-left: -10px;
    }
  }
`

export const BoxFilterContainer = styled.div<ButtonProps>`
  display: ${(props: ButtonProps) => `${props.modalOpen}`};
  background-color: rgba(0,0,0,.4);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 8; 
`

export const BoxFilterWhite = styled.ul<ButtonProps>`
  display: ${(props: ButtonProps) => `${props.modalOpen}`};
  position: absolute;   
  z-index: 9;
  padding-left: 0;
  top: -40px;

  @media ${device.tablet} {
    top: -100px;
  }
`

export const ButtonFilter = styled.button<ButtonProps>`
  align-items: center;
  justify-content: center;
  background: ${white};
  border-radius: ${rounded['6']};
  border: 1px solid ${grayscale['200']};
  color: ${grayscale['500']};
  display: flex;
  margin: 24px 0;
  width: 113px;
  height: 31px;
  position: relative;
  outline: none !important;
  z-index: 9;

  &.active {
    background: ${grayscale['500']};
    color: ${white};
  }
`
