import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Layout from 'src/layouts/BaseLayout'
import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import DocumentInformation from './DocumentInformation/_index'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'

import { Wrapper, ContentHeader, ScrollLink, OurPerformance, OurPerformanceBackground, Contact } from './style'

const Csirt = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <ContentHeader className='bg-white'>
          <div className='container pb-md-5 mb-md-5'>
            <div className='row justify-content-md-between align-items-center'>
              <div className='col-12 col-md-6 col-lg-5 order-md-last pt-2 pt-md-0'>
                <Img fluid={data.headerCsirtInterMobile.fluid} alt='Um casal em frente a um computador' className='d-lg-none' />
                <Img fluid={data.headerCsirtInterDesktop.fluid} alt='Um casal em frente a um computador' className='d-none d-lg-block float-xl-right' />
              </div>
              <div className='col-12 col-md-6 col-lg-6 col-xl-5 pr-lg-0 pt-4 pt-md-0'>
                <h1 className='fs-32 lh-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 text-grayscale--500 mb-3 mb-md-4'>
                  CSIRT Inter
                </h1>
                <p className='fs-18 lh-22 fs-md-18 lh-20 text-grayscale--500 mb-5 pb-5 mb-md-0 pb-md-0'>
                  A Equipe de Resposta e Tratamento de Incidentes de Segurança da Informação (Computer Security Incident
                  Response Team - CSIRT Inter) é responsável por receber, analisar, processar e responder os incidentes
                  de segurança envolvendo o Grupo Inter.
                </p>
              </div>
            </div>
          </div>
          <ScrollTo
            to='#documento-csirt'
            section='dobra_01'
            sectionName='CSIRT Inter'
            elementName='Saiba mais'
          >
            <ScrollLink>
              <p className='text-orange--extra mb-0 fs-18 lh-22 fw-600'>Saiba mais</p>
              <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
            </ScrollLink>
          </ScrollTo>
        </ContentHeader>
        <OurPerformance
          id='nossa-atuacao'
          className='py-md-0 bg-orange--extra d-md-flex justify-content-between align-items-md-center overflow-hidden'
        >
          <OurPerformanceBackground
            fluid={[
              data.bgNossaAtuacaoMobile.fluid,
              {
                ...data.bgNossaAtuacaoTablet.fluid,
                media: '(min-width: 768px)',
              },
              {
                ...data.bgNossaAtuacaoDesktopLG.fluid,
                media: '(min-width: 992px)',
              },
              {
                ...data.bgNossaAtuacaoDesktopXL.fluid,
                media: '(min-width: 1200px)',
              },
            ]}
          />
          <div className='container pb-5 pb-md-0'>
            <div className='col-12 col-md-6 offset-md-6'>
              <h2 className='fs-24 lh-28 fs-md-32 lh-md-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 text-white pt-4 pt-md-0'>
                Nossa atuação
              </h2>
              <p className='fs-18 lh-22 text-white pt-3 mb-0'>
                O CSIRT atua na detecção, análise, resposta e prevenção de incidentes de segurança, além de
                contribuir para o esforço nacional de cibersegurança no âmbito da Rede Nacional de CSIRT.
              </p>
            </div>
          </div>
        </OurPerformance>
        <DocumentInformation type='en' />
        <Contact id='contact-csirt' className='py-5'>
          <div className='container'>
            <div className='row justify-content-md-between align-items-center'>
              <div className='col-12 col-md-6 col-lg-5 order-md-last'>
                <Img fluid={data.ContatoCsirtMobile.fluid} alt='Um rapaz sorrindo falando ao celular' className='d-lg-none' />
                <Img fluid={data.ContatoCsirtDesktop.fluid} alt='Um rapaz sorrindo falando ao celular' className='d-none d-lg-block float-xl-right' />
              </div>
              <div className='col-12 col-md-5 col-xl-5 pt-4 pt-md-0'>
                <h2 className='fs-24 lh-28 fs-md-40 lh-md-47 fs-xl-48 lh-xl-56 text-grayscale--500 fw-600 mb-3 mb-md-4'>
                  <span className='d-block d-md-inline'>Precisa falar com a</span> CSIRT Inter?
                </h2>
                <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 mb-0'>
                  Entre em contato pelo e-mail
                  <a
                    href='mailto:csirt@bancointer.com.br'
                    className='d-block text-orange--extra fw-600'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        element_action: 'click button',
                        element_name: 'csirt@bancointer.com.br',
                        section_name: 'Precisa falar com a CSIRT Inter?',
                        redirect_url: 'mailto:csirt@bancointer.com.br',
                      })
                    }}
                  >
                    csirt@bancointer.com.br
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Contact>
      </Layout>
    </Wrapper>
  )
}

export default Csirt
