import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      headerCsirtInterMobile: imageSharp(fluid: {originalName: { regex: "/header-csirt-inter-mobile/" }}) {
        fluid(maxWidth: 336, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      headerCsirtInterDesktop: imageSharp(fluid: {originalName: { regex: "/header-csirt-inter-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }  
      bgNossaAtuacaoMobile: imageSharp(fluid: {originalName: { regex: "/bg-nossa-atuacao-mobile/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }
      bgNossaAtuacaoTablet: imageSharp(fluid: {originalName: { regex: "/bg-nossa-atuacao-tablet/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }  
      bgNossaAtuacaoDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-nossa-atuacao-desktop-lg/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }  
      bgNossaAtuacaoDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-nossa-atuacao-desktop-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      } 
      ContatoCsirtMobile: imageSharp(fluid: {originalName: { regex: "/contato-csirt-mobile/" }}) {
        fluid(maxWidth: 335, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }  
      ContatoCsirtDesktop: imageSharp(fluid: {originalName: { regex: "/contato-csirt-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize 
        }
      }         
    }
  `)

  return pageQuery
}

export default PageQuery
