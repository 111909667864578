import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import QuickAccess from 'src/components/QuickAccess'
import useWidth from 'src/hooks/window/useWidth'

import QuickAccessListJson from './assets/list.json'
import ENDocument from './documento-en.html'
import PTDocument from './documento-pt.html'

import { ContainerDocument, DocumentPage, BoxFilterContainer, BoxFilterWhite, ButtonFilter } from './style'

type DocumentProps = {
  type: string;
}

const DocumentInformation = ({ type }: DocumentProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const QuickAccessListEN = QuickAccessListJson.en
  const QuickAccessListPT = QuickAccessListJson.pt
  const [ version, setVersion ] = useState(type)
  const accessList = version === 'en' ? QuickAccessListEN : QuickAccessListPT
  const htmlContent = type === version ? ENDocument : PTDocument
  const [ isModalOpen, setIsModalOpen ] = useState('none')
  const [ isButtonActive, setIsButtonActive ] = useState('')
  const windowWidth = useWidth(300)
  const WIDTH_LG = 992

  function OpenModal () {
    setIsModalOpen('block')
    setIsButtonActive('active')
  }

  function cLoseModal () {
    setIsModalOpen('none')
    setIsButtonActive('')
  }

  return (
    <ContainerDocument id='documento-csirt' className='py-5'>
      <div className='container py-lg-3 py-xl-5'>
        <div className='row'>
          <div className='col-12 text-grayscale--500' id='documento-csirt'>
            <h2 className='fs-24 lh-28 fs-md-32 lh-md-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 text-uppercase mb-4'>
              CSIRT Inter RFC 2350
            </h2>
            <span className='d-block fs-18 lh-22'>
              <strong className='fw-700'>Version:</strong> 1.0
            </span>
            <span className='d-block fs-18 lh-22'>
              <strong className='fw-700'>Data:</strong> June 29, 2021
            </span>
            <span
              className={`cursor-pointer ${version === 'en' ? 'active' : ''}`}
              title='Versão EN'
              onClick={() => {
                setVersion('en')
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'EN',
                  section_name: 'CSIRT INTER RFC 2350',
                })
              }}
            >
              EN
            </span> <strong> | </strong>
            <span
              className={`cursor-pointer ${version === 'pt' ? 'active' : '' }`}
              title='Versão PT'
              onClick={() => {
                setVersion('pt')
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'PT',
                  section_name: 'CSIRT INTER RFC 2350',
                })
              }}
            >PT
            </span>
            {windowWidth < WIDTH_LG &&
              <ButtonFilter
                className={`fs-12 lh-15 ${isButtonActive}`}
                onClick={OpenModal}
                modalOpen={isButtonActive}
              >
                Acesso rápido
              </ButtonFilter>}
            <hr />
          </div>
          {windowWidth < WIDTH_LG
            ? (
              <>
                <BoxFilterContainer modalOpen={isModalOpen} onClick={cLoseModal} />
                <div className='col-12'>
                  <BoxFilterWhite className='text-grayscale--500' modalOpen={isModalOpen}>
                    <QuickAccess
                      accessListData={accessList}
                      setIsModalOpen={setIsModalOpen}
                      setIsButtonActive={setIsButtonActive}
                    />
                  </BoxFilterWhite>
                </div>
              </>
            )
          : (
            <QuickAccess
              accessListData={accessList}
              setIsModalOpen={setIsModalOpen}
              setIsButtonActive={setIsButtonActive}
            />
            )
          }
          <DocumentPage className='col-12 col-lg-8 pl-lg-4 col-xl-9'>
            {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
          </DocumentPage>
        </div>
      </div>
    </ContainerDocument>
  )
}

export default DocumentInformation
