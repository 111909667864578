import React, { useState } from 'react'
import slugify from 'slugify'
import ScrollTo from 'src/components/ScrollTo'
import { QuickAccessProps } from './type'
import { Container } from './style'

const QuickAccess = ({ accessListData, setIsModalOpen, setIsButtonActive }: QuickAccessProps) => {
  const [ activeItem, setActiveItem ] = useState(0)

  function HandleActive (index: number) {
    setActiveItem(index)
    setIsModalOpen('none')
    setIsButtonActive('')
  }

   return (
     <Container className='col-12 col-lg-4 col-xl-3'>
       <h3 className='fw-600 fs-24 lh-28'>Acesso rápido</h3>
       <ul>
         {Object.values(accessListData).map((items: string, index: number) => (
           <li
             className='fs-14 fs-xl-16 lh-19 text-grayscale--500' key={index}
             onClick={() => HandleActive(index)}
           >
             <ScrollTo
               styles={activeItem === index ? 'active' : ''}
               to={`#${slugify(items.replace(/\./g, ''), { lower: true })}`}
               section='dobra_03'
               sectionName='CSIRT INTER RFC 2350'
               elementName={items}
             >
               {items}
             </ScrollTo>
           </li>
        ))}
       </ul>
     </Container>
   )
}

export default QuickAccess
